import honeybadger from 'lib/errors/honeybadger';
import { PianoNewsletterEvent } from '../eventHandlers/BrandsLanding/EmailNewsletter';
import { UserSegment } from 'lib/hooks/useUserState';
import parseUserClient from '../../../../components/integrations/Mixpanel/parseUserClient';
import { getEndpoint } from 'lib/api/atk/endpoints';
import { updateNewsletterPreferences } from 'lib/hooks/useNewsletterState';
import AnalyticsService from 'lib/analytics/mixpanel/mixpanel-browser';
import events from 'lib/analytics/mixpanel/events';
import { setCookie } from 'cookies-next';

interface PostEmailForNewsletterProps
  extends Pick<PianoNewsletterEvent['params'], 'email' | 'newsletterid'> {
  type?: string;
  documentId?: string;
  documentTitle?: string;
  docType?: string;
  successCallback: () => void;
}

export const postEmailForNewsletter = async ({
  email,
  newsletterid,
  type,
  documentId,
  documentTitle,
  docType,
  successCallback,
}: PostEmailForNewsletterProps) => {
  try {
    const url = `${getEndpoint('v8')}/users/newsletter_preferences`;
    const method = 'POST';
    const user = parseUserClient();
    const segment = user?.segment as UserSegment;
    const emailCaptureType = type || '';
    const currentUrl = document.location.href || '';
    const exactTargetId = `${newsletterid}`;
    const response = await updateNewsletterPreferences(
      url,
      parseInt(exactTargetId),
      email,
      method,
      segment,
      currentUrl,
      emailCaptureType,
      documentId,
      documentTitle,
      docType,
    );
    if (response.ok) {
      if (segment === 'anonymous') {
        setCookie('email', email);
      }
      successCallback();
      const { global_resubscribe } = await response.json();
      if (global_resubscribe) {
        AnalyticsService.track(events.GLOBAL_RESUBSCRIBE_EMAIL, {
          location: 'newsletter preference center',
          email_capture_type: emailCaptureType,
          site_key: 'atk',
          email: email,
        });
      }
    }
  } catch (error) {
    honeybadger.notify(`newsletter preferences: ${error}`);
  }
};
