/**
 * Flickity is preventing pointerdown from triggering next mouse event
 *  that interacts with video player.
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LegacyZypeEmbed } from 'legacy-mise-ui/components/MediaEmbed';

type EventName =
  | 'playing'
  | 'pause'
  | 'addtrack'
  | 'loadstart'
  | 'readystate'
  | 'canplay';

/**
 * Mock types for player api use THEOplayer.players[0] to get actual
 *  object shape and update type as needed.
 */
export type Player = {
  abr: any;
  paused: boolean;
  visibility: {
    addObserver: (...args: any) => void;
  };
  players: any;
  play: () => void;
  pause: () => void;
  element: HTMLDivElement;
  muted: boolean;
  textTracks: [{ mode: string }] & {
    addEventListener(
      event: 'addtrack',
      eventCallback: (theoEvent: any) => void,
    ): void;
  };
  videoTracks: any;
  /** http://demo.theoplayer.com/using-events-examples */
  addEventListener(
    event: EventName,
    eventCallback: (theoEvent: any) => void,
  ): void;
  removeEventListener(
    event: EventName,
    eventCallback: (theoEvent: any) => void,
  ): void;
};

/**
 * playerPlayWhenVisible with autoplay removed, just stopping videos we lose visual of.
 * https://github.com/Americastestkitchen/espresso/blob/3612613f1d1a8e7481d129bf441c61f4a4dd6865/routes/homepage/Carousels/VideoCarousel/ZypeEmbedSlide.tsx#L51
 * pausedBy_ values no longer needed.
 */
function stopPlayerWhenNotVisible(player: Player) {
  player.visibility.addObserver(0.1, (step: number) => {
    if (step < 0.3) {
      if (!player.paused) {
        player.pause();
      }
    }
  });
}

/**
 * When player exists, we can just set this setter prop and it should be muted by the time autoplay comes in.
 *  Since we technically aren't autoplaying but calling play when an element comes into view, the autoplay isn't in
 *  the zype api arugments and doesn't set muted in configurations.
 */
function playerMuteWhenInitialized(player: Player) {
  player.muted = true;
}

/**
 * This audio track lazy loads on play only on ios mobile user agents. So we
 *  need to lazily set this the first time the video plays. The load event
 *  wont fire before we add theo hooks so we have to do both to handle
 *  desktop.
 * @param player
 */
function playerCaptionsOnWhenInitialized(player: Player) {
  player.textTracks.addEventListener('addtrack', () => {
    const hasTracks = player?.textTracks?.length > 0;
    if (hasTracks) {
      player.textTracks[0].mode = 'showing';
    }
  });
  const hasTracks = player?.textTracks?.length > 0;
  if (hasTracks) {
    player.textTracks[0].mode = 'showing';
  }
}

/**
 * Because of either atk lazy component or zype issues or development ssr, some videos
 *  appear missing. They are missing some dom manipulation that appends these classes.
 */
export function playerCheckHotReloadMissingSize(player: Player) {
  player.element?.parentElement?.parentElement?.classList.add(
    'zype-theo-player',
    'theoplayer-container',
    'video-js',
    'theoplayer-skin',
    'theo-seekbar-above-controls',
  );
}

/**
 * Setup for current player
 */
function usePlayerSetupEffect(player: Player | undefined) {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current && player !== undefined) {
      playerMuteWhenInitialized(player);
      playerCaptionsOnWhenInitialized(player);
      stopPlayerWhenNotVisible(player);
      playerCheckHotReloadMissingSize(player);
      initialized.current = true;
    }
  }, [player]);
}

/**
 * find closest parent with zypeReference utility that should wrap element
 */
export function findCurrentPlayerByZypeReference(zypeReferenceId: string) {
  return (player: Player) => {
    if (!player) return false;
    const closestRef = player.element.closest<HTMLDivElement>(
      '[data-zype-reference]',
    );
    return zypeReferenceId === closestRef?.dataset.zypeReference;
  };
}

/**
 * Sets a zype id since uuid don't correlate to anything in theoplayers
 */
export function zypeReference({ zypeId }: { zypeId: string }) {
  return { 'data-zype-reference': zypeId };
}

/**
 * Only way to get theo player instance from zype initialization.
 * @param zypeId find closest parent with zypeReference utility that should wrap element
 * @returns player or undefined when player is still initializing
 *  or has yet to be retrieved from window global
 */
function usePlayer(zypeId: string): Player | undefined {
  const [player, setPlayer] = useState(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!window.THEOplayer) return;
      if (!window.THEOplayer.players) return;

      const currentPlayer = window.THEOplayer.players.find(
        findCurrentPlayerByZypeReference(zypeId),
      );

      if (!currentPlayer) return;

      setPlayer(currentPlayer);
      clearInterval(interval);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [zypeId]);

  return player;
}

/**
 * For when ZypeEmbed is used in carousels.
 */
export default function ZypeEmbedSlide({ zypeId }: { zypeId: string }) {
  const player = usePlayer(zypeId);
  usePlayerSetupEffect(player);
  const zypeToken = process.env.ZYPE_PLAYER_API_KEY || '';

  return (
    <div
      onPointerDownCapture={(ev) => ev.stopPropagation()}
      {...zypeReference({ zypeId })}
    >
      <LegacyZypeEmbed source={zypeId} token={zypeToken} />
    </div>
  );
}

ZypeEmbedSlide.propTypes = {
  zypeId: PropTypes.string.isRequired,
};
