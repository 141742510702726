import { useEffect, useState } from 'react';
import styles from './FullWidthVideo.module.scss';
import analytics from 'lib/analytics';
import { LegacyZypeEmbed } from 'legacy-mise-ui/components/MediaEmbed';
import {
  zypeReference,
  findCurrentPlayerByZypeReference,
  playerCheckHotReloadMissingSize,
  Player,
} from 'routes/homepage/Carousels/VideoCarousel/ZypeEmbedSlide';
import FullWidthVideoLoading from './FullWidthVideoLoading';

export interface FullWidthVideoProps {
  source: string;
  videoTitle: string;
}

const FullWidthVideo: React.FC<FullWidthVideoProps> = ({
  source,
  videoTitle,
}) => {
  const [player, setPlayer] = useState<Player | undefined>(undefined);
  const zypeToken = process.env.ZYPE_PLAYER_API_KEY || '';

  useEffect(() => {
    const interval = setInterval(() => {
      if (!window.THEOplayer) return;
      if (!window.THEOplayer.players) return;

      const currentPlayer = window.THEOplayer.players.find(
        findCurrentPlayerByZypeReference(source),
      );

      if (!currentPlayer) return;

      setPlayer(currentPlayer);
      clearInterval(interval);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [source]);

  useEffect(() => {
    if (!player) return;
    playerCheckHotReloadMissingSize(player);

    player?.addEventListener('canplay', () => {
      // Force 1080p when video starts
      const currentVideo = player?.videoTracks[0];
      const highQuality = currentVideo?.qualities[3];
      currentVideo.targetQuality = highQuality;
    });

    player?.addEventListener('playing', () => {
      try {
        analytics.track(
          'INLINE_VIDEO_PLAY',
          { video_clicked: videoTitle },
          { transport: 'sendBeacon' },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }
    });
  }, [player, source, videoTitle]);

  return (
    <div className={`${styles.wrapSpinner} ${!player && styles.loaderSpinner}`}>
      <div
        data-testid="full-width-video"
        className={styles.fullWidthVideoWrapper}
        {...zypeReference({ zypeId: source })}
      >
        <LegacyZypeEmbed source={source} token={zypeToken} />
      </div>
      {!player ? <FullWidthVideoLoading /> : null}
    </div>
  );
};

export default FullWidthVideo;
